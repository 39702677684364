<template>
  <div :class="[s.card, classes]">
    <slot></slot>
  </div>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    classes: String,
  },
  setup() {
    return { s };
  },
};
</script>
