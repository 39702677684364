import { createRouter, createWebHistory } from "vue-router";
import { Auth, Hub } from "aws-amplify";

import Home from "@/views/Home";
import Login from "@/views/Login";
import Skills from "@/views/Skills";
import Discover from "@/views/Discover";
import Activities from "@/views/Activities";
import Article from "@/views/Article";
import Notifications from "@/views/Notifications";
import Profile from "@/views/Profile";
import Test from "@/views/test.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/skills",
    name: "Skills",
    component: Skills,
  },
  {
    path: "/discover",
    name: "Discover",
    component: Discover,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/activities",
    name: "Activities",
    component: Activities,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/article/:id",
    name: "Article",
    component: Article,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      layout: "Main",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => {
  const token = localStorage.getItem("authToken");

  const checkUser = async () => {
    // const userData = jwt.verify(token);
    // console.log(userData);
    // if (loggedIn) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("user", user);
      if (to.path === "/login") {
        window.location = "/";
      }
      return next();
    } catch (error) {
      console.log("error:", error);
      if (to.path !== "/login") {
        window.location = "/login";
      }
      return next();
    }
    // } else {
    //   if (to.path !== "/login") {
    //     window.location = "/login";
    //   }
    //   return next();
    // }
  };
  if (token) {
    checkUser();
  } else {
    if (to.path !== "/login") {
      window.location = "/login";
    }
    return next();
  }
});

export default router;
