<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <path
      d="M7.05005 13.3C10.5018 13.3 13.3 10.5018 13.3 7.05005C13.3 3.59827 10.5018 0.800049 7.05005 0.800049C3.59827 0.800049 0.800049 3.59827 0.800049 7.05005C0.800049 10.5018 3.59827 13.3 7.05005 13.3Z"
      stroke="#1D40BF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.05005 3.30005V7.05005L9.55005 8.30005"
      stroke="#1D40BF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    classes: String,
  },
};
</script>
