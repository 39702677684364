export default {
  save(state, item) {
    const isNew = state.items.some((i) => i.id !== item.id);

    if (isNew) {
      this.commit("updateItem", item);
    } else {
      state.items = [...state.items, { ...item }];
    }
  },
  updateItem(state, item) {
    state.items = items.map((i) => {
      if (i.id === item.id) {
        return item;
      }
      return i;
    });
  },
  setChosenItemId(state, choisenItemId) {
    state.choisenItemId = choisenItemId;
  },
  setIsModalBoost(state, isModalBoost) {
    state.isModalBoost = isModalBoost;
  },  setContent(state, newContent) {
    state.newContent = newContent;
  },
};
