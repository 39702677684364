<template>
  <div :class="s.aside">
    <div :class="s.searchWrapper">
      <Search classes="search" placeholder="Search Notifications" />
    </div>
    <div :class="s.topics">
      <div :class="[s.title, s.topicsTitle]">TRENDING TOPICS</div>
      <div :class="s.topicsItems">
        <Tag
          :classes="s.topicsItem"
          v-for="(t, index) in tags"
          :key="index"
          :title="t.title"
          :backgroundColor="t.backgroundColor"
        />
      </div>
    </div>
    <div :class="s.experts">
      <div :class="[s.title, s.expertsTitle]">MEET THE EXPERTS</div>
      <MeetTheExperts
        name="Olivia"
        years="15"
        day="Tue, Fr."
        time="3.30 - 4.30 PM"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Search from "@/components/Forms/Search";
import Tag from "@/components/Common/Tag";
import MeetTheExperts from "@/components/MeetTheExperts";

export default {
  components: {
    Search,
    Tag,
    MeetTheExperts,
  },
  setup() {
    const tags = ref([
      { title: "Node.js", backgroundColor: "#e8f9e8" },
      { title: "Web Architecture", backgroundColor: "#e4f0ff" },
      { title: "AI", backgroundColor: "#cff5ea" },
      { title: "Python", backgroundColor: "#ffe9e5" },
      { title: "Github & Source Tree", backgroundColor: "#ede5ff" },
    ]);

    return { s, tags };
  },
};
</script>
