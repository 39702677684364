<template>
  <img :src="ArrowLeft" :class="classes" />
</template>

<script>
import ArrowLeft from "@/assets/images/icons/arrowLeft.svg";

export default {
  props: { classes: String },
  setup() {
    return { ArrowLeft };
  },
};
</script>
