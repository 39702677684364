<template>
  <div :class="s.wrapper">
    <Modal v-if="isModalBoost" :close="closenModalBoost" />
    <Article
      name="John"
      surname="William"
      action="Shared an article"
      time_ago="23 min ago"
      :views="202"
      title="How to write highly readable React
    code — 10 coding style tips"
      body="When doing code reviews, developers
    rarely get enough time to truly understand each line of code we’re
    reviewing."
      :likes="24"
      :classes="s.article"
      :isBoost="true"
    />
  </div>
  <Aside />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import s from "./index.module.sass";

import Aside from "@/components/Home/Aside";
import Article from "@/components/Article";
import Likes from "@/components/Common/Button/LikesCount";
import CommentsCount from "@/components/Common/Button/CommentsCount";
import CreateComment from "@/components/Comments/CreateComment";
import Comments from "@/components/Comments";
import Boost from "@/components/Common/Button/Boost";
import Modal from "@/components/Common/Modal/Boost";

export default {
  components: {
    Aside,
    Article,
    Likes,
    CommentsCount,
    CreateComment,
    Comments,
    Boost,
    Modal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isCommentsBlock = ref(false);
    const comments = ref([
      {
        id: 1,
        name: "Peter",
        surname: "Hene",
        time_ago: "23 min ago",
        text: "Good one, highly recommended",
      },
      {
        id: 2,
        name: "Rakesh",
        surname: "SK",
        time_ago: "23 min ago",
        text: "I agree, Peter",
      },
    ]);
    const isModalBoost = computed(() => store.getters["content/isModalBoost"]);

    const setIsCommentsBlock = (value) => {
      isCommentsBlock.value = value;
    };

    const openModalBoost = () => {
      store.dispatch("content/setIsModalBoost", true);
    };

    const closenModalBoost = () => {
      store.dispatch("content/setIsModalBoost", false);
    };

    return {
      s,
      isCommentsBlock,
      comments,
      isModalBoost,
      setIsCommentsBlock,
      openModalBoost,
      closenModalBoost,
    };
  },
};
</script>
