<template>
  <div :class="[s.images, classes]">
    <div
      v-for="(c, index) in chosienImages"
      :key="index"
      :class="s.image"
      :style="{
        background: `url(${c}) no-repeat`,
        backgroundSize: 'cover',
      }"
    />
    <div :class="[s.image, s.imageAdd]">
      <label :class="s.uploadLabel" for="backgroung-img">
        <PlusIcon />
      </label>
      <input
        :class="s.uploadInput"
        type="file"
        name="img"
        id="backgroung-img"
        @input="handleFileUpload"
      />
    </div>
  </div>
</template>

<script>
import { watch, computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import PlusIcon from "@/components/Icons/Plus";

export default {
  components: { PlusIcon },
  props: {
    newArticle: {
      type: Object,
      required: true,
    },
    classes: String,
  },
  setup() {
    const store = useStore();
    const newArticle = computed(() => store.getters["content/newContent"]);
    const chosienImages = ref([]);

    watch(newArticle, () => {
      if (newArticle.value?.images) {
        chosienImages.value = newArticle.value.images;
      }
    });

    const handleFileUpload = (e) => {
      const file = e.target.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          chosienImages.value.push(e.target.result);
          store.dispatch("content/save", {
            ...newArticle,
            images: [...newArticle.images, ...chosienImages.value],
          });
        };
        reader.readAsDataURL(file[0]);
      }
    };

    return { s, chosienImages, handleFileUpload };
  },
};
</script>
