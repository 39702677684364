<template>
  <div :class="s.rangeWrapper">
    <input
      ref="range"
      @input="onChangeRange"
      :class="s.range"
      type="range"
      value="0"
      :min="min"
      :max="max"
      :steps="steps"
    />
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    min: Number,
    max: Number,
    steps: Number,
    classes: String,
    backgroundColor: String,
  },
  setup({ value }) {
    return {
      s,
    };
  },
};
</script>
