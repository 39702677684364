<template>
  <Modal :close="close" :classes="s.modal">
    <div :class="s.header">
      <div :class="s.title">
        Boosting this content for
      </div>
      <div :class="s.help">(Select as many as applicable)</div>
    </div>
    <form @submit.prevent="handleSubmit" :class="s.content">
      <div :class="s.checkboxes">
        <Checkbox
          v-for="(c, index) in checkboxes"
          :key="index"
          :name="c.name"
          :value="c.name"
          :label="c.label"
          :classes="s.checkbox"
          :checked="c.value"
          @change="(e) => setCheckboxesVal(e, c.name)"
        />
      </div>
      <Button htmlType="submit" :classes="s.button" width="100%" :height="45">
        BOOST THIS FEED
      </Button>
      <div @click="close" :class="s.cancelWrapper">
        <span :class="s.cancel">Cancel</span>
      </div>
    </form>
  </Modal>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Modal from "@/components/Common/Modal";
import Checkbox from "@/components/Forms/Checkbox";
import Button from "@/components/Common/Button";

export default {
  components: { Modal, Checkbox, Button },
  props: {
    close: Function,
  },
  setup() {
    const store = useStore();

    const checkboxes = ref([
      { name: "beginner", label: "Beginner" },
      { name: "intermediate", label: "Intermediate" },
      { name: "avdvanced", label: "Avdvanced" },
      { name: "master", label: "Master" },
    ]);
    const checkboxesVal = ref([]);
    const choisenItemId = computed(
      () => store.getters["content/choisenItemId"]
    );

    const setCheckboxesVal = (e, name) => {
      checkboxes.value = checkboxes.value.map((c) => {
        if (c.name === name && !checkboxesVal.value.includes(name)) {
          return { ...c, value: e.target.checked };
        }
        return c;
      });
    };

    const handleSubmit = () => {
      if (choisenItemId.value) {
        store.dispatch("content/boost", {
          id: choisenItemId,
          levels: checkboxes.value,
        });
      }
    };

    return { s, checkboxes, setCheckboxesVal, handleSubmit };
  },
};
</script>
