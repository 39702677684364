import { axiosInstance } from "@/api";

export const skillApi = {
  getRecommendedSkills: async () => {
    return await axiosInstance.get(`/recommended`);
  },
  autocomplete: async (text) => {
    return await axiosInstance.get(`/autocomplete/${text}`);
  },
};
