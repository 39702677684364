<template>
  <layout v-if="layout">
    <router-view />
  </layout>
  <router-view v-else />
</template>

<script>
import { watch, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import MainLayout from "@/layouts/Main";
import SkillsLayout from "@/layouts/Skills";

export default {
  components: { MainLayout },

  setup() {
    const route = useRoute();
    const layout = ref(null);

    const setLayout = async () => {
      console.log(route.meta);

      switch (route.meta.layout) {
        case "Main":
          layout.value = MainLayout;
          break;
        default:
          return;
      }
    };

    onMounted(() => {
      setLayout();
    });

    watch([route, route.meta], () => {
      console.log(route.meta);
      setLayout();
    });

    return { layout };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/index.sass";
</style>
