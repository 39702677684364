<template>
  <div>
    <Button v-if="isBoost" @click="click" :classes="[s.thanks, s.button]">
      Thanks For Boost
    </Button>
    <Button v-else @click="click" :classes="[s.boost, s.button]">
      <LightingIcon :classes="s.icon" />Boost</Button
    >
  </div>
</template>

<script>
import s from "./index.module.sass";

import Button from "@/components/Common/Button";
import LightingIcon from "@/components/Icons/Lighting";

export default {
  components: { Button, LightingIcon },
  props: {
    click: Function,
  },
  setup() {
    return { s };
  },
};
</script>
