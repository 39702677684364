import { axiosInstance } from "@/api";

export const eventApi = {
  getItems: async (pageSize, pageNumber) => {
    const { data } = await axiosInstance.post(`/events`, {
      pageSize,
      pageNumber,
    });
    return data;
  },
};
