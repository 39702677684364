<template>
  <Modal :close="onClose" :classes="s.modal">
    <div :class="s.contentWrapper">
      <Avatar :class="s.avatar" />
      <form @submit.prevent="handleSubmmit" :class="s.content">
        <TextArea
          name="description"
          :class="s.textarea"
          :height="250"
          :value="descriptionVal"
          @input="setDescriptionVal"
        />
        <Tags v-if="isTags" :newArticle="newArticle" />
        <Images
          v-if="isImages"
          :classes="s.images"
          :newArticle="newArticle"
          :images="images"
        />
        <div :class="s.actions">
          <div :class="s.actionsList">
            <div
              v-for="(a, index) in actions"
              :key="index"
              @click="() => setItems(a.slug)"
              :class="[
                s.action,
                { [s.actionActive]: choisenAction === a.slug },
              ]"
            >
              <img :src="a.icon" />
            </div>
          </div>
          <div :class="s.actionsRight">
            <SettingsIcon :classes="s.settings" />
            <Button htmlType="submit" :class="s.submit"><CheckIcon /></Button>
          </div>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Modal from "@/components/Common/Modal";
import Avatar from "@/components/Common/Avatar";
import Button from "@/components/Common/Button";
import TextArea from "@/components/Forms/TextArea";
import LabelIcon from "@/assets/images/icons/label.svg";
import SoabakaIcon from "@/assets/images/icons/sobaka.svg";
import SceneryIcon from "@/assets/images/icons/scenery.svg";
import ChartBarsIcon from "@/assets/images/icons/chartBars.svg";
import CheckIcon from "@/components/Icons/Check";
import SettingsIcon from "@/components/Icons/Settings";

import Tags from "./Tags";
import Images from "./Images";

export default {
  components: {
    Modal,
    Avatar,
    Button,
    TextArea,
    SettingsIcon,
    CheckIcon,
    Tags,
    Images,
  },
  setup() {
    const store = useStore();
    const actions = ref([
      { icon: LabelIcon, slug: "add-teg" },
      { icon: SoabakaIcon, slug: "add-url" },
      { icon: SceneryIcon, slug: "add-image" },
      { icon: ChartBarsIcon, slug: "add-chart" },
    ]);
    const isTags = ref(false);
    const isImages = ref(false);
    const descriptionVal = ref("");
    const newArticle = computed(() => store.getters["content/newContent"]);
    const choisenAction = ref(null);
    const setDescriptionVal = (e) => {
      descriptionVal.value = e.target.value;

      store.dispatch("content/setNewContent", {
        ...newArticle.value,
        description: descriptionVal.value,
      });
    };

    const onClose = () => {
      store.dispatch("app/setIsNewPostModal", false);
    };

    function setItems(slug) {
      choisenAction.value = slug;

      console.log("choisenAction", choisenAction.value);

      switch (slug) {
        case "add-teg":
          isTags.value = !isTags.value;
          break;
        case "add-image":
          isImages.value = !isImages.value;
          break;
        default:
          break;
      }
    }

    const handleSubmit = () => {
      store.dispatch("content/save", newArticle.value);
    };

    return {
      s,
      actions,
      choisenAction,
      isTags,
      isImages,
      newArticle,
      setDescriptionVal,
      onClose,
      setItems,
      handleSubmit,
    };
  },
};
</script>
