<template>
  <div :class="s.wrapper">
    <Form @submit="handleSubmit" :validation-schema="schema" :class="s.form">
      <div v-for="(i, index) in inputs" :key="index" :class="s.field">
        <div :class="s.label">
          {{ i.placeholder }}
        </div>
        <Input
          :name="i.name"
          :placeholder="i.placeholder"
          :value="i.value"
          :width="i.width"
          :height="i.height"
          :error="i.meta.touched && !i.meta.dirty"
        />
      </div>
    </Form>
  </div>
</template>

<script>
import { onMounted, watch, ref } from "vue";
import { Form, useForm, useField } from "vee-validate";
import * as Yup from "yup";

import s from "./index.module.sass";

import Input from "@/components/Forms/Input";

export default {
  components: { Input, Form },
  setup() {
    const inputProps = { width: "100%", height: 39 };
    const schema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phone: Yup.string().required("Phone is required"),
      email: Yup.string()
        .email()
        .required("Email is required"),
    });
    useForm({
      validationSchema: schema,
    });
    const firstNameInput = useField("firstName");
    const lastNameInput = useField("lastName");
    const phoneInput = useField("phone");
    const emailInput = useField("email");
    const data = ref(null);
    const inputs = ref([]);

    onMounted(() => {
      console.log("onMounted.account");

      const getData = async () => {
        data.value = await store.dispatch("user/getItem");
      };
      getData();
    });

    watch(data, () => {
      if (data) {
        inputs.value = [
          {
            ...firstNameInput,
            ...inputProps,
            name: "firstName",
            value: data.value.firstName,
            placeholder: "First Name",
          },
          {
            ...lastNameInput,
            ...inputProps,
            name: "lastName",
            value: data.value.lastName,
            placeholder: "Last Name",
          },
          {
            ...phoneInput,
            ...inputProps,
            name: "phone",
            placeholder: "Contact Phone",
          },
          {
            ...emailInput,
            ...inputProps,
            name: "email",
            value: data.value.email,
            placeholder: "Email Address",
          },
        ];
        console.log("data", data.value.firstName);
        console.log("inputs", inputs.value);
      }
    });

    const handleSubmit = (values) => {
      // console.log("values", values);
    };

    return { s, inputs, schema, handleSubmit };
  },
};
</script>
