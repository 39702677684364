<template>
  <div :class="s.menu">
    <Logo :classes="s.logo" />
    <div :class="s.items">
      <div
        :class="[s.itemWrapper, 'menu__item']"
        v-for="(m, index) in items"
        :key="index"
      >
        <router-link
          v-if="m.url !== 'logout'"
          :to="m.url !== 'home' ? '/' + m.url : '/'"
          :class="[s.item]"
        >
          <div :class="s.iconWrapper">
            <img :class="[s.icon]" :src="m.icon" :alt="m.text" />
          </div>
          <div :class="s.text">{{ m.text }}</div>
        </router-link>
        <div v-else @click="setSelected" :class="[s.item]">
          <div :class="s.iconWrapper">
            <img :class="[s.icon]" :src="m.icon" :alt="m.text" />
          </div>
          <div :class="s.text">{{ m.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Logo from "@/components/Common/Logo";

export default {
  components: { Logo },
  props: {
    items: {
      type: Array,
      reuired: true,
    },
  },
  setup() {
    return { s };
  },
};
</script>
