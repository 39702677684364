<template>
  <div :class="s.wrapper">
    <div :class="s.title">Discover</div>
    <Search :classes="s.search" placeholder="Search Topics" />
    <div v-for="i in items" :key="i.id">
      <Article
        v-if="i.contentType === 'Article'"
        :item="i"
        :classes="s.article"
      />
      <ShortVideos
        v-if="i.contentType === 'Video'"
        :item="i"
        :classes="s.shortVideo"
      />
      <Podcast
        v-if="i.contentType === 'Podcast'"
        :item="i"
        :classes="s.article"
      />
    </div>
  </div>
  <div></div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import MainLayout from "@/layouts/Main";
import Article from "@/components/Article";
import Likes from "@/components/Common/Button/LikesCount";
import CommentsCount from "@/components/Common/Button/CommentsCount";
import CreateComment from "@/components/Comments/CreateComment";
import Comments from "@/components/Comments";
import Boost from "@/components/Common/Button/Boost";
import Search from "@/components/Forms/Search";
import Podcast from "@/components/Podcast";

export default {
  components: {
    MainLayout,
    Article,
    Likes,
    CommentsCount,
    CreateComment,
    Comments,
    Boost,
    Search,
    Podcast,
  },
  setup() {
    const store = useStore();

    const isCommentsBlockArticle = ref(false);
    const isCommentsBlockPodcast = ref(false);
    const comments = ref([
      {
        id: 1,
        name: "Peter",
        surname: "Hene",
        time_ago: "23 min ago",
        text: "Good one, highly recommended",
      },
      {
        id: 2,
        name: "Rakesh",
        surname: "SK",
        time_ago: "23 min ago",
        text: "I agree, Peter",
      },
    ]);
    const items = computed(() => store.getters["event/items"]);

    onMounted(() => {
      store.dispatch("event/setItems", { pageSize: 5, pageNumber: 1 });
    });

    const setIsCommentsBlockArticle = (value) => {
      isCommentsBlockArticle.value = value;
    };

    const setIsCommentsBlockPodcast = (value) => {
      isCommentsBlockPodcast.value = value;
    };

    return {
      s,
      isCommentsBlockArticle,
      isCommentsBlockPodcast,
      comments,
      items,
      setIsCommentsBlockArticle,
      setIsCommentsBlockPodcast,
    };
  },
};
</script>
