<template>
  <div :class="s.wrapper">
    <Avatar :classes="s.avatar" />
    <div :class="s.input__wrapper">
      <input
        :class="s.input"
        :value="textVal"
        @input="textVal = $event.target.value"
        @keyup.enter="handleSubmit"
        type="text"
        placeholder="Write a comment…"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Avatar from "@/components/Common/Avatar";

export default {
  components: { Avatar },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup({ id }) {
    const store = useStore();

    const textVal = ref(null);

    const handleSubmit = () => {
      store.dispatch("content/addComment", { id, text: textVal.value });
      textVal.value = "";
    };

    return { s, textVal, handleSubmit };
  },
};
</script>
