<template>
  <div :class="[s.wrapper, classes]">
    <Avatar :classes="s.avatar" />
    <div>
      <div :class="s.row">
        <span :class="s.fullname">{{ name }} {{ surname }}</span>
        <span :class="s.timeAgo">{{ time_ago }}</span>
      </div>
      <div :class="s.text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import s from "./index.module.sass";

import Avatar from "@/components/Common/Avatar";

export default {
  components: { Avatar },
  props: {
    name: {
      type: String,
      require: true,
    },
    surname: {
      type: String,
      require: true,
    },
    time_ago: {
      type: String,
      require: true,
    },
    text: {
      type: String,
      require: true,
    },
    classes: String,
  },
  setup() {
    return { s };
  },
};
</script>
