<template>
  <div :class="s.wrapper">
    <div :class="s.statistik">
      <ChartCircle :items="statistikItems" :classes="s.chart" />
      <div :class="s.statistkItems">
        <StatistkItem
          v-for="(i, index) in statistikItems"
          :key="index"
          :name="i.name"
          :value="i.value"
          :color="i.color"
        />
      </div>
    </div>
    <div :class="s.rewards">
      <Reward
        v-for="r in rewardItems"
        :key="r.id"
        :title="r.title"
        :skill="r.skill"
        :sharedCount="r.sharedCount"
        :classes="s.reward"
      />
    </div>
    <SkillRating :classes="s.skillRating" />
    <SkillRating :classes="s.skillRating" />
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import ChartCircle from "@/components/Common/Chart/Circle";
import ChartLine from "@/components/Common/Chart/Line";
import StatistkItem from "@/components/Profile/Dashboard/StatistkItem";
import Reward from "@/components/Profile/Dashboard/Reward";
import SkillRating from "@/components/SkillRating";

export default {
  components: { ChartCircle, StatistkItem, Reward, ChartLine, SkillRating },
  setup() {
    const statistikItems = ref([
      { value: 50, color: "red" },
      { value: 70, color: "pink" },
      { value: 40, color: "orange" },
    ]);
    const rewardItems = ref([
      { id: 1, title: "Expert badge on", skill: "React", sharedCount: 3 },
      { id: 2, title: "Expert badge on", skill: "React", sharedCount: 3 },
      { id: 3, title: "Expert badge on", skill: "React", sharedCount: 3 },
    ]);
    const skillRatings = ref([
      {
        title: "System Design",
        items: [
          { name: "Self", value: 75 },
          { name: "Peer", value: 75 },
        ],
      },
      {
        title: "Architecture",
        items: [
          { name: "Self", value: 75 },
          { name: "Peer", value: 75 },
        ],
      },
    ]);

    return { s, statistikItems, rewardItems, skillRatings };
  },
};
</script>
