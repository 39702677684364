<template>
  <div ref="wrapperEl" :class="[s.wrapper, classes]">
    <div
      v-for="(i, index) in items"
      :key="i.id"
      :class="s.svgWrapper"
      :style="{
        width: `${radiuses[index] * 2}px`,
        height: `${radiuses[index] * 2}px`,
      }"
    >
      <div v-if="index + 1 === items.length" :class="s.label">
        {{ i.value }}%
      </div>
      <svg :class="s.svg">
        <circle
          stroke="#f4f4f4"
          :stroke-width="stroke"
          :stroke-dasharray="progressCircle(index).circumference"
          :style="{
            width: `${radiuses[index] * 2}px`,
            height: `${radiuses[index] * 2}px`,
            strokeDashoffset:
              progressCircle(index).circumference -
              (100 / 100) * progressCircle(index).circumference,
          }"
          :r="radiuses[index] - stroke * 2"
          :cx="radiuses[index]"
          :cy="radiuses[index]"
          :class="s.circle"
        />
        <circle
          :stroke="i.color"
          :stroke-width="stroke"
          :stroke-dasharray="progressCircle(index).circumference"
          :style="{
            width: `${radiuses[index] * 2}px`,
            height: `${radiuses[index] * 2}px`,
            strokeDashoffset:
              progressCircle(index).circumference -
              (i.value / 100) * progressCircle(index).circumference,
          }"
          :r="radiuses[index] - stroke * 2"
          :cx="radiuses[index]"
          :cy="radiuses[index]"
          :class="s.circle"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    classes: String,
  },
  setup({ items }) {
    const radiuses = ref([]);
    const wrapperEl = ref(null);
    const radius = 45;
    const stroke = 12;

    items.map((i, index) =>
      radiuses.value.push(index === 0 ? radius : index * 15 + radius)
    );

    onMounted(() => {
      wrapperEl.value.style.width = `${radiuses.value[
        radiuses.value.length - 1
      ] * 2}px`;
      wrapperEl.value.style.height = `${radiuses.value[
        radiuses.value.length - 1
      ] * 2}px`;
    });

    const progressCircle = (index) => {
      const normalizedRadius = radiuses.value[index] - stroke * 2;

      return {
        radius,
        stroke,
        normalizedRadius,
        circumference: normalizedRadius * 2 * Math.PI,
      };
    };

    return {
      s,
      wrapperEl,
      radius,
      radiuses,
      stroke,
      progressCircle,
    };
  },
};
</script>
