import { Auth } from "aws-amplify";

import { userApi } from "@/api";

export default {
  setAccessToken({ commit }) {
    const accessToken = localStorage.getItem("authToken");

    commit("setIdToken", accessToken);
  },
  async login({ commit }, { username, password }) {
    try {
      const user = await Auth.signIn(username, password);

      console.log(user);

      localStorage.setItem(
        "authToken",
        user.signInUserSession.idToken.jwtToken
      );

      commit("setIdToken", user.signInUserSession.idToken.jwtToken);

      window.location = "/";
    } catch (error) {
      console.log("error", error);
    }
  },
  async saveOnBoarding({ commit }, data) {
    const res = await userApi.saveOnBoarding(data);
  },
  async setSkills({ commit }, data) {
    const res = await userApi.getSkills();
    commit("setSkills", res.skills);
  },
  async getItem({ commit }) {
    return await userApi.getItem();
  },
};
