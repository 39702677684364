<template>
  <div :class="s.wrapper">
    <div :class="s.header">
      <Logo />
    </div>
    <div :class="s.bkgWrapper">
      <img :src="Background" alt="background" :class="s.bkg" />
    </div>
    <Card :class="s.card">
      <div :class="s.cardHeader">
        <div :class="s.back">
          <ArrowLeft :classes="s.backIcon" /><span :class="s.backText"
            >Back</span
          >
        </div>
        <div :class="s.progress">
          <div
            v-for="(skill, index) in skillsLength"
            :key="skill"
            :class="s.progressItemWrapper"
          >
            <div
              :class="[
                s.progressItem,
                { [s.progressItemActive]: index <= activeItem },
              ]"
            />
            <div v-if="index === activeItem" :class="s.progressText">
              {{ rateText }}
            </div>
          </div>
        </div>
      </div>

      <div :class="s.title">{{ title }}</div>
      <slot></slot>
      <Button :click="click" :classes="s.submit">
        {{ buttonText }}
      </Button>
    </Card>
  </div>
</template>

<script>
import Background from "@/assets/images/backgrounds/skills.svg";

import s from "./index.module.sass";

import Card from "@/components/Common/Card";
import Button from "@/components/Common/Button";
import Logo from "@/components/Common/Logo";
import ArrowLeft from "@/components/Icons/ArrowLeft";

export default {
  name: "skills-layout",
  components: {
    Card,
    Button,
    Logo,
    ArrowLeft,
  },
  props: {
    skillsLength: {
      type: Number,
      required: true,
    },
    activeItem: {
      type: Number,
      required: true,
    },
    rateText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: String,
    click: Function,
  },
  setup() {
    return { s, Background };
  },
};
</script>
