import { contentApi } from "@/api";

export default {
  async save({ commit }, item) {
    const res = await contentApi.save(item);

    if (!res.code) {
      commit("save", item);
    }
  },
  async like({ commit }, id) {
    const res = await contentApi.like(id);
  },
  async unlike({ commit }, id) {
    const res = await contentApi.unlike(id);
  },
  async boost({ commit }, { id, levels }) {
    const res = await contentApi.boost(id, levels);
  },
  async duration({ commit }, id) {
    const res = await contentApi.unlike(id);

    if (!res.code) {
    }
  },
  async getComments({ commit }, id) {
    await contentApi.comments(id);
  },
  async addComment({ commit }, { id, text }) {
    await contentApi.addComment({ id, text });
  },
  async preview({ commit }, url) {
    await contentApi.preview(url);
  },
  setChosenItemId({ commit }, id) {
    commit("setChosenItemId", id);
  },
  setNewContent({ commit }, newContent) {
    commit("setNewContent", newContent);
  },
  setIsModalBoost({ commit }, isModalBoost) {
    commit("setIsModalBoost", isModalBoost);
  },
};
