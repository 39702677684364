import { createStore } from "vuex";

import app from "./modules/app";
import content from "./modules/content";
import event from "./modules/event";
import podcast from "./modules/podcast";
import skill from "./modules/skill";
import user from "./modules/user";
import userFeed from "./modules/userFeed";

const store = createStore({
  modules: {
    app,
    content,
    event,
    skill,
    podcast,
    user,
    userFeed,
  },
});
window.store = store;
export default store;
