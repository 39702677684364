<template>
  <div @click="setIsNewPostModal" :class="[s.wrapper, classes]">
    <Avatar :class="s.avatar" />
    <div :class="s.input">Hello {{ name }}, Tell us what you want to post</div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Avatar from "@/components/Common/Avatar";

export default {
  components: { Avatar },
  props: {
    classes: String,
  },
  setup() {
    const store = useStore();

    const setIsNewPostModal = () => {
      store.dispatch("app/setIsNewPostModal", true);
    };

    return { s, setIsNewPostModal };
  },
};
</script>
