<template>
  <div>
    <SkillsLayout
      :click="setActiveItem"
      :skillsLength="skills.length"
      :activeItem="activeItem"
      :rateText="skills[activeItem].rateText"
      :title="skills[activeItem].title"
      :buttonText="skills[activeItem].buttonText"
    >
      <PickSkills v-if="activeItem === 0" />
      <RateSkills v-if="activeItem === 1" />
      <StyleSkills v-if="activeItem === 2" />
    </SkillsLayout>
  </div>
</template>

<script>
import { ref } from "vue";

import SkillsLayout from "@/layouts/Skills";
import PickSkills from "@/modules/Skills/PickSkills";
import RateSkills from "@/modules/Skills/Rate";
import StyleSkills from "@/modules/Skills/Style";

export default {
  components: { SkillsLayout, PickSkills, RateSkills, StyleSkills },
  setup() {
    const skills = ref([
      {
        rateText: "Won’t take long",
        title: "Pick Your Skills",
        buttonText: "Next",
      },
      {
        rateText: "Few more details",
        title: "Rate Your Skills",
        buttonText: "Next",
      },
      {
        rateText: "Almost There",
        title: "Pick Your Style",
        buttonText: "Finish",
      },
    ]);

    const activeItem = ref(0);

    const setActiveItem = () => {
      if (activeItem.value + 1 !== skills.value.length) {
        activeItem.value++;
      }
    };

    return { activeItem, skills, setActiveItem };
  },
};
</script>

<style lang="sass" scoped></style>
