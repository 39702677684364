<template>
  <div :class="s.wrapper">
    <div :class="s.columnLeft">
      <div :class="s.columnLeftText">
        The best curated skills to help you grow in you career
      </div>
    </div>
    <div :class="s.columnRight">
      <div :class="s.columnRightHeader">
        <Logo />
        <div :class="s.columnRightHeaderText">Enterprise Login</div>
      </div>
      <div :class="s.welcome">
        <span :class="s.welcomeTitle">Hello,</span> Hope you enjoy our Skill
        Experience
      </div>
      <Form @submit="handleSubmit" :validation-schema="schema" :class="s.form">
        <div :class="s.field" v-for="(i, index) in inputs" :key="index">
          <Input
            :input="i.handleInput"
            :blur="i.handleBlur"
            :name="i.name"
            :placeholder="i.placeholder"
            :type="i.type"
            :backgroundColor="i.backgroundColor"
            width="100%"
            :value="i.value && i.value.value"
            :error="i.meta.touched && !i.meta.dirty"
          />
        </div>
        <div :class="s.field">
          <Button :classes="s.submit" :htmlType="submit">Sign In</Button>
        </div>
      </Form>

      <div :class="s.forgotPass">Forgot Password?</div>
      <div :class="s.networksWrapper">
        <div :class="s.networksTitle">Or continue with</div>
        <div :class="s.networks">
          <div :class="s.network"><Google /></div>
          <div :class="s.network"><Facebook /></div>
          <div :class="s.network"><Microsoft /></div>
        </div>
      </div>
      <div :class="s.registerText">
        If you don’t have an account You can
        <span :class="s.registerTextActive">Register here!</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Form, useForm, useField } from "vee-validate";
import * as Yup from "yup";

import s from "./index.module.sass";

import Input from "@/components/Forms/Input";
import Button from "@/components/Common/Button";
import Logo from "@/components/Common/Logo";
import Google from "@/components/Icons/Google";
import Facebook from "@/components/Icons/Facebook";
import Microsoft from "@/components/Icons/Microsoft";

export default {
  components: { Form, Input, Button, Logo, Google, Facebook, Microsoft },
  setup() {
    const store = useStore();

    const schema = Yup.object().shape({
      username: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
    });
    useForm({
      validationSchema: schema,
    });
    const usernameInput = useField("username");
    const passwordInput = useField("password");
    const inputs = ref([
      {
        ...usernameInput,
        name: "username",
        placeholder: "Enter Email",
        backgroundColor: "#e8eff7",
        width: "100%",
        type: "text",
      },
      {
        ...passwordInput,
        name: "password",
        placeholder: "Password",
        type: "password",
        backgroundColor: "#e8eff7",
        width: "100%",
      },
    ]);
    window.inputs = inputs.value;

    const handleSubmit = ({ username, password }) => {
      store.dispatch("user/login", {
        username,
        password,
      });
    };

    return { s, schema, inputs, handleSubmit };
  },
};
</script>
