<template>
  <div :class="[s.wrapper, classes]" :style="{ width }">
    <input
      @imput="setValue"
      :class="s.input"
      type="text"
      :value="value"
      :name="name"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    value: String,
    name: {
      type: String,
      requiired: true,
    },
    placeholder: {
      type: String,
      requiired: true,
    },
    width: String,
    classes: String,
  },
  setup() {
    const value = ref("");

    const setValue = (e) => {
      value.value = e.target.value;
    };

    return { s, value, setValue };
  },
};
</script>
