import { axiosInstance } from "@/api";

export const userApi = {
  async login({ username, password }) {
    return await axiosInstance.post(`/Auth/login`, {
      username,
      password,
    });
  },
  async getItem() {
    const { data } = await axiosInstance.get(`/userprofile`);
    return data;
  },
  async updateItem(phone) {
    return await axiosInstance.put(`/update`, { phone });
  },
  async saveOnBoarding({ learningSkills, currentSkills, learningStyles }) {
    return await axiosInstance.post(`/saveonboarding`, {
      learningSkills,
      currentSkills,
      learningStyles,
    });
  },
  async getSkills() {
    return await axiosInstance.get(`/skills`);
  },
};
