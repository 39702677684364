<template>
  <div :class="[s.wrapper, classes]">
    <img v-if="img" :src="img" :class="s.avatar" />
    <div v-else :class="s.plug" />
  </div>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    img: String,
    classes: String,
  },
  setup() {
    return { s };
  },
};
</script>
