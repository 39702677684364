<template>
  <img :src="Microsoft" :class="classes" />
</template>

<script>
import Microsoft from "@/assets/images/icons/microsoft.svg";

export default {
  props: { classes: String },
  setup() {
    return { Microsoft };
  },
};
</script>
