<template>
  <div :class="s.wrapper">
    <div :class="s.header">
      <div :class="s.avatarWrapper"><div :class="s.isOnline" /></div>
      <div :class="s.headerContent">
        <div :class="s.fullname">Mark Bolt</div>
        <div :class="s.job">Application Developer</div>
      </div>
    </div>
    <Tabs :items="tabs" @activeItem="setActiveItem" :classes="s.tabs" />
    <slot />
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Tabs from "@/components/Common/Tabs";

export default {
  name: "profile-layout",
  components: { Tabs },
  setup(props, { emit }) {
    const tabs = ref(["Dashboard", "Account", "Bookmarks"]);

    const setActiveItem = (value) => {
      emit("setActiveItem", value);
    };

    return { s, tabs, setActiveItem };
  },
};
</script>
