<template> <img :class="classes" :src="Plus" alt="Plus" /> </template>

<script>
import Plus from "@/assets/images/icons/plus.svg";

export default {
  props: {
    classes: String,
  },
  setup() {
    return { Plus };
  },
};
</script>
