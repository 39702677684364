<template>
  <div v-if="isDetails" :class="s.wrapper">
    <router-link to="/" :class="s.icon">back</router-link>
    <span :class="s.title">Details</span>
  </div>
  <div v-else :class="s.wrapper">
    <div :class="s.iconWrapper">
      <div :class="s.burger">
        <div :class="s.burgerItem" />
        <div :class="s.burgerItem" />
        <div :class="s.burgerItem" />
      </div>
    </div>
    <Logo @click="onOpen" />
  </div>
</template>

<script>
import s from "./index.module.sass";

import Logo from "@/components/Icons/Logo";

export default {
  components: { Logo },
  props: {
    isDetails: Boolean,
  },
  setup() {
    const onOpen = () => {
      store.dispatch("app/setIsMobMenu", true);
    };

    return { s, onOpen };
  },
};
</script>
