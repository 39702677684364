<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <path
      d="M8.49317 1.36603C9.42137 0.830127 10.565 0.830127 11.4932 1.36603L17.5864 4.88397C18.5146 5.41987 19.0864 6.41025 19.0864 7.48205V14.5179C19.0864 15.5897 18.5146 16.5801 17.5864 17.116L11.4932 20.634C10.565 21.1699 9.42137 21.1699 8.49317 20.634L2.3999 17.116C1.4717 16.5801 0.899902 15.5897 0.899902 14.5179V7.48205C0.899902 6.41025 1.4717 5.41987 2.3999 4.88397L8.49317 1.36603Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M5.8999 11L8.8999 13.5L14.3999 7.5"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="9.99317"
        y1="-2"
        x2="9.99317"
        y2="30.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9900FF" />
        <stop offset="1" stop-color="#980100" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    classes: String,
  },
};
</script>
