<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <path
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
      stroke="#FD0D1B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 5L5 9"
      stroke="#FD0D1B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 5L9 9"
      stroke="#FD0D1B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    classes: String,
  },
};
</script>
