<template>
  <div :class="s.wrapper" :classes="[classes]" :styles="{ width }">
    <CreateComment :id="contentId" />
    <div :class="s.comments">
      <Comment
        :classes="s.comment"
        v-for="c in comments"
        :key="c.id"
        :name="c.name"
        :surname="c.surname"
        :time_ago="c.time_ago"
        :text="c.text"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import CreateComment from "./CreateComment";
import Comment from "./Comment";

export default {
  components: { CreateComment, Comment },
  props: {
    contentId: {
      type: Number,
      required: true,
    },
    classes: String,
    width: String,
  },
  setup({ contentId }) {
    const store = useStore();

    const comments = ref([]);

    const setComments = async () => {
      comments.value = await store.dispatch("content/getComments", contentId);
    };

    onMounted(() => {
      setComments();
    });

    return { s, comments };
  },
};
</script>
