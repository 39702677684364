<template>
  <div :class="s.wrapper">
    <div :class="[s.title, s.discoverTitle]">Discover</div>
    <Search :classes="s.search" placeholder="Search Topics" />
    <div :class="s.topicsWrapper">
      <div :class="[s.title, s.topicsTitle]">TRENDING TOPICS</div>
      <div :class="s.topics">
        <Tag
          :classes="s.topicsItem"
          v-for="(t, index) in tags"
          :key="index"
          :title="t.title"
          :backgroundColor="t.backgroundColor"
        />
      </div>
    </div>
    <div :class="s.discoverWrapper">
      <Items :items="discoverItems" />
    </div>
  </div>
  <div></div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import MainLayout from "@/layouts/Main";
import Search from "@/components/Forms/Search";
import Tag from "@/components/Common/Tag";
import Items from "@/components/Discover/Items";

export default {
  components: { MainLayout, Search, Tag, Items },
  setup() {
    const tags = ref([
      { title: "Node.js", backgroundColor: "#e8f9e8" },
      { title: "Web Architecture", backgroundColor: "#e4f0ff" },
      { title: "AI", backgroundColor: "#cff5ea" },
      { title: "Python", backgroundColor: "#ffe9e5" },
      { title: "Github & Source Tree", backgroundColor: "#ede5ff" },
    ]);
    const discoverItems = ref([
      {
        id: 1,
        title: "Title go here",
        desc: "Some Desc",
        items: [
          { title: "Data Analytics Course", img: "" },
          { title: "Natural Language Processing", img: "" },
          { title: "Business Intelligence Data Analytics", img: "" },
        ],
      },
      {
        id: 2,
        title: "Title go here",
        desc: "Some Desc",
        items: [
          { title: "Data Analytics Course", img: "" },
          { title: "Natural Language Processing", img: "" },
          { title: "Business Intelligence Data Analytics", img: "" },
        ],
      },
    ]);

    return { s, tags, discoverItems };
  },
};
</script>
