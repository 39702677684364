export default {
  setItems(state, items) {
    state.items = items;
  },
  setPageNumber(state, pageNumber) {
    state.pageNumber = pageNumber;
  },
  setPageSize(state, pageSize) {
    state.pageSize = pageSize;
  },
  setTotalResults(state, totalResults) {
    state.totalResults = totalResults;
  },
};
