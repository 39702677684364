<template>
  <div :class="[s.wrapper, classes]" @click="setLikes">
    <HeartOutlined v-if="!isLiked" :class="s.icon" />
    <HeartFilled v-else :class="s.icon" />
    <div :class="s.countWarpper">
      <span :class="s.count">{{ count }}</span>
      <span :class="s.text">likes</span>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import HeartOutlined from "@/components/Icons/HeartOutlined";
import HeartFilled from "@/components/Icons/HeartFilled";

export default {
  components: { HeartOutlined, HeartFilled },
  props: {
    count: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
    isLiked: Boolean,
    classes: String,
  },
  setup(props) {
    const store = useStore();

    const isLiked = ref(props.isLiked);

    const setLikes = () => {
      isLiked.value = !isLiked.value;

      if (!isLiked.value) {
        store.dispatch("content/like", props.contentId);
      } else {
        store.dispatch("content/unlike", props.contentId);
      }
    };

    return { s, isLiked, setLikes };
  },
};
</script>
