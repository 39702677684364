<template>
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <path
      d="M2.06522 0H9.1087L5.97826 6.4H9.5L2.06522 16L3.63043 9.2H0.5L2.06522 0Z"
      fill="#EECC00"
    />
  </svg>
</template>

<script>
export default {
  props: {
    classes: String,
  },
};
</script>
