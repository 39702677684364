<template> <img :src="Settings" alt="Settings" :class="classes" /> </template>

<script>
import Settings from "@/assets/images/icons/settings.svg";

export default {
  props: {
    classes: String,
  },
  setup() {
    return { Settings };
  },
};
</script>
