import { skillApi } from "@/api";

export default {
  async setRecommendedSkills({ commit }) {
    const res = await skillApi.getRecommendedSkills();

    if (!res.messages[0].code) {
      commit("setRecommendedSkills", res.skills);
    }
  },
  async setAutocomplete({ commit }, text) {
    const res = await skillApi.autocomplete(text);

    if (!res.messages[0].code) {
      return res.skills;
    }
  },
};
