<template>
  <div :class="s.wrapper" :style="{ width }">
    <div :class="s.steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
        @click="() => setActiveItem(index)"
        :class="[s.step, { [s.stepActive]: index === activeItem }]"
      >
        <Arrows v-if="index === activeItem" />
      </div>
    </div>
    <div :class="s.line" />
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Range from "@/components/Forms/Input/Range";
import Arrows from "@/components/Icons/Arrows";

export default {
  components: { Range, Arrows },
  props: {
    item: {
      type: Object,
      required: true,
    },
    steps: {
      type: Number,
      required: true,
    },
    width: String,
  },
  setup(props, { emit }) {
    const activeItem = ref(null);

    const setActiveItem = (index) => {
      activeItem.value = index;
    };

    return { s, activeItem, setActiveItem };
  },
};
</script>
