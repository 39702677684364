import axios from "axios";

import { config } from "@/config";

const token = localStorage.getItem("authToken");
export const axiosInstance = axios.create({
  baseURL: config.baseURL,
  // http://localhost:8080/activities
  // http://ce-dev-deploy-frontend.s3-website-us-east-1.amazonaws.com/Prod/
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export { contentApi } from "./content";
export { eventApi } from "./event";
export { skillApi } from "./skill";
export { userFeedApi } from "./userFeed";
export { userApi } from "./user";
