<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
    @click="click"
  >
    <circle cx="25" cy="25" r="25" fill="#FF5300" />
    <path
      d="M32.3905 24.6221C33.0854 25.0011 33.0854 25.9989 32.3905 26.3779L21.7289 32.1934C21.0625 32.5568 20.25 32.0745 20.25 31.3155L20.25 19.6845C20.25 18.9255 21.0625 18.4432 21.7289 18.8066L32.3905 24.6221Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: { classes: String, click: Function },
};
</script>
