import { axiosInstance } from "@/api";

export const userFeedApi = {
  getItems: async (pageSize, pageNumber, search) => {
    const { data } = await axiosInstance.post(`/userFeed/feed`, {
      pageSize,
      pageNumber,
      search,
    });
    return data;
  },
};
