<template>
  <div :class="[s.card, s.specialisedWrapper]">
    <div :class="s.cardTitle">Skills that you want to learn</div>
    <div :class="[s.skills, s.specialised]">
      <Tag
        v-for="skill in skillsWant"
        :key="skill.id"
        :classes="[s.tag, s.tagActive]"
        :title="skill.name"
      />
    </div>
    <div :class="s.searchWrapper">
      <input
        type="text"
        :value="skillSpecialisedVal"
        @input="setSkillsSpecialised"
        :class="s.search"
        placeholder="Search more skills"
      />
    </div>
    <div :class="[s.skills, s.specialized]">
      <Tag
        v-for="skill in recommendedSkills"
        :key="skill.id"
        :classes="s.tag"
        :title="skill.name"
      />
    </div>
  </div>
  <div :class="[s.card, s.wantWrapper]">
    <div :class="s.cardTitle">Skills that you are specialist</div>
    <div :class="[s.skills, s.want]">
      <Tag
        v-for="skill in skillsWant"
        :key="skill.id"
        :classes="s.tag"
        :title="skill.name"
      />
    </div>
    <div :class="s.searchWrapper">
      <input
        type="text"
        :value="skillWantVal"
        @input="setSkillsWant"
        :class="s.search"
        placeholder="Search more skills"
      />
    </div>
    <div :class="[s.skills, s.want]">
      <Tag
        v-for="skill in recommendedSkills"
        :key="skill.id"
        :classes="s.tag"
        :title="skill.name"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Tag from "@/components/Common/Tag";

export default {
  components: { Tag },
  setup() {
    const store = useStore();

    const recommendedSkills = computed(
      () => store.getters["skill/recommendedSkills"]
    );
    const skillsSpecialised = ref([]);
    const skillsWant = ref([]);
    const skillSpecialisedVal = ref("");
    const skillWantVal = ref("");
    // const skillsSpecialised = ref(["JS", "Python", "XD"]);
    // const skillsWant = ref(["Japan", "Python"]);

    onMounted(() => {
      store.dispatch("skill/setRecommendedSkills");
    });

    const setSkillsSpecialised = (e) => {
      skillSpecialisedVal.value = e.target.value;
      skillsSpecialised.value.push(skillSpecialisedVal.value);
      store.dispatch("skill/setSkillsSpecialised", skillsSpecialised);
      skillSpecialisedVal.value = "";
    };

    const setSkillsWant = (e) => {
      skillWantVal.value = e.target.value;
      skillsWant.value.push(skillWantVal.value);
      store.dispatch("skill/setSkillsWant", skillsWant);
      skillWantVal.value = "";
    };

    return {
      s,
      skillsSpecialised,
      skillsWant,
      skillSpecialisedVal,
      skillWantVal,
      recommendedSkills,
      setSkillsSpecialised,
      setSkillsWant,
    };
  },
};
</script>
