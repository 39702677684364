<template>
  <img @click="onclick" :src="Logo" alt="Logo" :class="classes" />
</template>

<script>
import Logo from "@/assets/images/logo.svg";

export default {
  props: {
    onclick: Function,
    classes: String,
  },
  setup() {
    return { Logo };
  },
};
</script>
