import { axiosInstance } from "@/api";

const baseUrl = "/content";

export const contentApi = {
  save: async (data) => {
    return await axiosInstance.post(`${baseUrl}/save`, { ...data });
  },
  like: async (id) => {
    return await axiosInstance.post(`${baseUrl}/like/${id}`);
  },
  unlike: async (id) => {
    return await axiosInstance.post(`${baseUrl}/unlike/${id}`);
  },
  boost: async (id, levels) => {
    return await axiosInstance.post(`${baseUrl}/boost/${id}`, { levels });
  },
  duration: async (id) => {
    return await axiosInstance.get(`${baseUrl}/view/${id}/${duration}`);
  },
  comments: async (id) => {
    return await axiosInstance.get(`${baseUrl}/comments/${id}`);
  },
  addComment: async ({ contentId, text }) => {
    return await axiosInstance.post(`${baseUrl}/comment`, { contentId, text });
  },
  preview: async (url) => {
    return await axiosInstance.post(`${baseUrl}/preview`, { url });
  },
};
