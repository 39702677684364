<template>
  <div></div>
</template>

<script>
import { ref } from "vue";

export default {
  components: {},
  setup() {
    return {};
  },
};
</script>
