<template>
  <div :class="[s.wrapper, classes]" :style="{ backgroundColor }">
    <div :class="s.tag">{{ title }}</div>
  </div>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    title: { type: String, required: true },
    classes: String,
    backgroundColor: String,
    borderColor: String,
  },
  setup() {
    return { s };
  },
};
</script>
