<template>
  <div :class="s.wrapper">
    <div :class="s.items">
      <div v-for="i in items" :key="i.id" :class="[s.item, 'menu__item']">
        <router-link :to="i.url !== 'home' ? '/' + i.url : '/'" :class="s.link">
          <div :class="s.iconWrapper">
            <img :class="[s.icon]" :src="i.icon" :alt="i.text" />
          </div>
          <div :class="s.text">{{ i.text }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { s };
  },
};
</script>
