<template>
  <img :src="Google" :class="classes" />
</template>

<script>
import Google from "@/assets/images/icons/google.svg";

export default {
  props: { classes: String },
  setup() {
    return { Google };
  },
};
</script>
