<template>
  <div :class="[s.wrapper, classes]">
    <PlayRounded @click="togglePlay" v-if="isPause" :class="s.play" />
    <PlayRounded @click="togglePlay" v-else :class="s.play" />

    <div :class="s.timeItem">{{ currentTime }}</div>

    <div :class="s.rangeWrapper">
      <input
        ref="range"
        @input="onChangeRange"
        :class="s.range"
        type="range"
        value="0"
        min="0"
        max="100"
      />
      <div
        ref="rangeCircle"
        :class="s.rangeCircleWrapper"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @mouseup="mouseup"
      >
        <div :class="s.rangeCircle"></div>
      </div>
    </div>

    <div :class="s.timeItem">{{ duration }}</div>

    <div :class="s.pause"></div>
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

import s from "./index.module.sass";

import PlayRounded from "@/components/Icons/PlayRounded";

export default {
  components: { PlayRounded },
  props: {
    value: {
      type: String,
      required: true,
    },
    classes: String,
  },
  setup({ value }) {
    const range = ref(null);
    const rangeValue = ref(0);
    const rangeCircle = ref(null);
    const isPause = ref(true);
    const currentTime = ref("00.00");
    const duration = ref("00.00");
    const audio = new Audio();
    const isMoveRange = ref(false);
    audio.src = value;

    const play = () => {
      audio.src = value;
      audio.play();
    };

    const togglePlay = () => {
      if (audio.paused) {
        audio.play();
        isPause.value = false;
      } else {
        audio.pause();
        isPause.value = true;
      }
    };

    // audio.addEventListener("durationchange", (e) => {});

    audio.addEventListener("timeupdate", (e) => {
      currentTime.value = moment.utc(audio.currentTime * 1000).format("mm.ss");
      duration.value = moment.utc(audio.duration * 1000).format("mm.ss");
      const pos = audio.currentTime / audio.duration;
      range.value.value = pos * 100;
      rangeCircle.value.style.left = `${pos * 100}%`;
    });

    const onChangeRange = (e) => {
      audio.play();
      audio.currentTime = (range.value.value * audio.duration) / 100;

      // const pos = audio.currentTime / audio.duration;
      // range.value.value = pos * 100;
      // d -100
      // r -?
      // d =

      rangeCircle.value.style.left = `${range.value.value}%`;
    };

    const mousedown = (e) => {
      isMoveRange.value = true;
    };

    const mouseup = (e) => {
      isMoveRange.value = false;
      // audio.play();
      // audio.currentTime = (range.value.value * audio.duration) / 100;
    };

    return {
      s,
      range,
      audio,
      rangeCircle,
      isPause,
      currentTime,
      duration,
      togglePlay,
      onChangeRange,
      mousedown,
      mousemove,
      mouseup,
    };
  },
};
</script>
