<template>
  <textarea
    :class="[s.textarea, classes]"
    :style="{ height: `${height}px` }"
    :name="name"
    :placeholder="placeholder"
    @imput="setValue"
  />
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    name: {
      type: String,
      requiired: true,
    },
    height: Number,
    placeholder: String,
    classes: String,
  },
  setup(props, { emit }) {
    const value = ref(props.value || "");

    const setValue = (e) => {
      value.value = e.target.value;
      emit("");
    };

    return { s, value, setValue };
  },
};
</script>
