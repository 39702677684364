<template>
  <svg
    @click="onclick"
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <path
      id="Контур_14548"
      data-name="Контур 14548"
      d="M42,4.2,37.8,0,21,16.8,4.2,0,0,4.2,16.8,21,0,37.8,4.2,42,21,25.2,37.8,42,42,37.8,25.2,21Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
import Close from "@/assets/images/icons/close.svg";

export default {
  props: {
    onclick: Function,
    classes: String,
  },
  setup() {
    return { Close };
  },
};
</script>
