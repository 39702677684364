export default {
  setRecommendedSkills(state, recommendedSkills) {
    state.recommendedSkills = recommendedSkills;
  },
  setAutocomplete(state, autocomplete) {
    state.autocomplete = autocomplete;
  },
  setSkillsSpecialised(state, skillsSpecialised) {
    state.skillsSpecialised = skillsSpecialised;
  },
  setSkillsWant(state, skillsWant) {
    state.skillsWant = skillsWant;
  },
};
