<template>
  <div :class="s.wrapper" :style="{ width, height: `${height}px` }">
    <Field :name="name" v-model="value" v-slot="{ field }">
      <input
        v-bind="field"
        @input="input"
        @blur="blur"
        :type="type"
        v-model="value"
        :placeholder="placeholder"
        :style="{ backgroundColor }"
        :class="[s.input, { [s.danger]: error }]"
      />
    </Field>
    <ErrorMessage
      :class="s.errMessageWrapper"
      :name="name"
      as="div"
      v-slot="{ message }"
    >
      <CloseRounded />
      <span :class="s.errMessage" :name="name">{{ message }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

import s from "./index.module.sass";

import CloseRounded from "@/components/Icons/CloseRounded";

export default {
  components: { Field, ErrorMessage, CloseRounded },
  props: {
    value: String,
    type: String,
    name: {
      type: String,
      requiired: true,
    },
    placeholder: {
      type: String,
      requiired: true,
    },
    error: String,
    backgroundColor: String,
    width: String,
    height: Number,
    input: Function,
    blur: Function,
  },
  setup() {
    return { s };
  },
};
</script>
