<template>
  <label :class="[s.label, classes]">
    {{ label }}
    <input @input="setValue" :class="s.input" type="checkbox" />
    <span :class="s.checkbox" />
  </label>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: String,
    classes: String,
  },
  setup() {
    const value = ref(null);

    const setValue = (e) => {
      value.value = e.target.value;
    };

    return { s, setValue };
  },
};
</script>
