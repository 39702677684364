<template> <img :src="Check" alt="Check" /> </template>

<script>
import Check from "@/assets/images/icons/check.svg";

export default {
  setup() {
    return { Check };
  },
};
</script>
