<template>
  <div :class="s.wrapper">
    <div :class="s.title">{{ title }}</div>
    <div :class="s.btnWrapper">
      <Button width="64px" :height="27" :borderRadius="3" :fontSize="9"
        >Explore</Button
      >
    </div>
  </div>
</template>

<script>
import s from "./index.module.sass";

import Button from "@/components/Common/Button";

export default {
  components: { Button },
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { s };
  },
};
</script>
