<template>
  <div :class="[s.wrapper, classes]">
    <div
      v-for="(t, index) in choisenTags"
      :key="index"
      style="display: inline-flex"
    >
      <Tag :title="t" :classes="s.tag" />
      <input
        v-if="index + 1 === choisenTags.length"
        name="tag"
        :value="textVal"
        @input="textVal = $event.target.value"
        @keyup.enter="setChoisenTags"
        :class="s.input"
      />
    </div>
    <input
      v-if="!choisenTags.length"
      name="tag"
      :value="textVal"
      @input="textVal = $event.target.value"
      @keyup.enter="setChoisenTags"
      :class="s.input"
    />
  </div>
</template>

<script>
import { watch, computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Tag from "@/components/Common/Tag";

export default {
  components: { Tag },
  props: {
    newArticle: {
      type: Object,
      required: true,
    },
    classes: String,
  },
  setup() {
    const store = useStore();

    const textVal = ref("");
    const newArticle = computed(() => store.getters["content/newContent"]);
    const choisenTags = ref([]);
    const autocompleteItems = ref(null);

    watch(newArticle, () => {
      if (newArticle.value?.tags) {
        choisenTags.value = newArticle.value.tags;
      }
    });

    const setAutocompleteItems = async () => {
      // autocompleteItems.value = await store.dispatch(
      //   "skills/setAutocomplete",
      //   textVal
      // );
    };

    watch(textVal, () => {
      setAutocompleteItems();
    });

    const setChoisenTags = () => {
      choisenTags.value.push(textVal.value);
      textVal.value = "";

      store.dispatch("content/setNewContent", {
        ...newArticle,
        tags: [...newArticle.tags, ...choisenTags.value],
      });
    };

    return { s, choisenTags, textVal, setChoisenTags };
  },
};
</script>
