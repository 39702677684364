<template>
  <div :class="[s.wrapper, classes]" @click="setIsCommentsBlock">
    <Comment :class="s.icon" />
    <div :class="s.countWrarpper">
      <span :class="s.count">{{ count }}</span>
      <span :class="s.text">comments</span>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Comment from "@/components/Icons/Comment";

export default {
  components: { Comment },
  props: {
    count: {
      type: Number,
      required: true,
    },
    classes: String,
  },
  setup(props, { emit }) {
    const isCommentsBlock = ref(false);

    const setIsCommentsBlock = () => {
      isCommentsBlock.value = !isCommentsBlock.value;
      emit("isCommentsBlock", isCommentsBlock.value);
    };

    return { s, setIsCommentsBlock };
  },
};
</script>
