<template>
  <div :class="s.wrapper">
    <div
      :class="[s.item, { [s.itemActive]: activeItems.includes(i.id) }]"
      v-for="i in items"
      :key="i.id"
      @click="() => setActiveItems(i.id)"
    >
      <img v-if="i.img" :src="i.img" />
      <div v-else :class="s.img" />
      <div :class="s.text">{{ i.text }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  setup() {
    const items = ref([
      { id: 1, img: null, text: "I love reading / writing" },
      { id: 2, img: null, text: "I binge on videos" },
      { id: 3, img: null, text: "Avid gamer" },
      { id: 4, img: null, text: "I like to think" },
      { id: 5, img: null, text: "Audio books / Podcast" },
    ]);
    const activeItems = ref([]);

    const setActiveItems = (id) => {
      if (activeItems.value.includes(id)) {
        activeItems.value = activeItems.value.filter((a) => a !== id);
      } else {
        activeItems.value.push(id);
      }
    };

    return { s, items, activeItems, setActiveItems };
  },
};
</script>
