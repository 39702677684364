<template>
  <img :src="Facebook" :class="classes" />
</template>

<script>
import Facebook from "@/assets/images/icons/facebook.svg";

export default {
  props: { classes: String },
  setup() {
    return { Facebook };
  },
};
</script>
