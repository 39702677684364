<template>
  <div :class="s.wrapper">
    <div :class="s.content">
      <Avatar :classes="s.avatar" />
      <div :class="s.column">
        <div :class="s.header">
          <span :class="s.username">{{ username }}</span>
          <span :class="s.timeAgo">{{ time_ago }}</span>
        </div>
        <div :class="s.text">{{ text }}</div>
      </div>
    </div>
    <div v-if="!isLatest" :class="s.border" />
  </div>
</template>

<script>
import s from "./index.module.sass";

import Avatar from "@/components/Common/Avatar";

export default {
  components: { Avatar },
  props: {
    username: {
      type: String,
      require: true,
    },
    time_ago: {
      type: String,
      require: true,
    },
    text: {
      type: String,
      require: true,
    },
    isLatest: Boolean,
  },
  setup() {
    return { s };
  },
};
</script>
