<template>
  <div :class="s.wrapper">
    <CreatePostModal v-if="isNewPostModal" />
    <CreatePost name="Mark" avatar="" :classes="s.createPost" />
    <Article v-for="i in items" :key="i.id" :item="i" :classes="s.article" />
  </div>
  <Aside />
</template>

<script>
import { watch, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import MainLayout from "@/layouts/Main";
import ShortVideos from "@/components/ShortVideos";
import Article from "@/components/Article";

import Aside from "@/components/Home/Aside";
import CreatePost from "../../components/Home/CreatePost/index.vue";
import CreatePostModal from "@/components/Common/Modal/CreatePost";

export default {
  components: {
    MainLayout,
    ShortVideos,
    Article,
    Aside,
    CreatePost,
    CreatePostModal,
  },
  setup() {
    const store = useStore();

    const isNewPostModal = computed(() => store.getters["app/isNewPostModal"]);
    const itemsData = computed(() => store.getters["userFeed/items"]);
    const items = ref([]);

    watch(itemsData, () => {
      items.value = itemsData.value;
    });

    onMounted(() => {
      // store.dispatch("content/setArticles");
      store.dispatch("userFeed/setItems", {
        pageSize: 5,
        pageNumber: 1,
        search: "",
      });
    });

    return { s, isNewPostModal, items };
  },
};
</script>
