import { createApp } from "vue";
import Amplify from "aws-amplify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { config } from "@/config";

Amplify.configure({
    aws_appsync_region: config.region,
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.clientId,
    },
  });

createApp(App)
  .use(store)
  .use(router)
  .mount("#app");
