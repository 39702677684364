<template>
  <div :class="[s.wrapper, classes]">
    <div :class="s.header">
      <Avatar :classes="s.avatar" :img="userAvatar" />
      <div>
        <div :class="s.row">
          <span :class="s.fullname">{{ userName }}</span>
          <span :class="s.addedOn">{{ addedOn }}</span>
        </div>
        <div :class="s.action">{{ action }}</div>
      </div>
    </div>
    <div :class="s.content">
      <div
        :class="s.mainImgWrapper"
        :style="{
          background: `url(${thumbnail}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }"
      >
        <div :class="s.views">{{ views }} Views</div>
        <div :class="s.mainImg" />
      </div>
      <router-link :href="`/article/${id}`" :class="[s.link, s.title]">
        {{ title }}
      </router-link>
      <div :class="s.description">{{ description }}</div>
    </div>
    <div :class="s.actions">
      <div :class="s.actionsFirst">
        <Likes
          :classes="s.likesCount"
          :contentId="id"
          :count="likes"
          :isLiked="isLiked"
        />
        <CommentsCount
          :classes="commentsCount"
          :count="comments.length"
          @isCommentsBlock="setIsCommentsBlock"
        />
      </div>
      <div v-if="isBoost"><Boost :click="openModalBoost" /></div>
    </div>
    <div v-if="isCommentsBlock" :class="s.commentsWrapper">
      <Comments :contentId="id" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Avatar from "@/components/Common/Avatar";
import Likes from "@/components/Common/Button/LikesCount";
import CommentsCount from "@/components/Common/Button/CommentsCount";
import Comments from "@/components/Comments";
import Boost from "@/components/Common/Button/Boost";

export default {
  components: { Avatar, Likes, CommentsCount, Comments, Boost },
  props: {
    item: {
      type: Object,
      required: true,
    },
    classes: String,
    isBoost: Boolean,
  },
  setup({ item }) {
    const isCommentsBlock = ref(false);
    const comments = ref([
      {
        id: 1,
        userName: "Peter Hene",
        addedOn: "23 min ago",
        text: "Good one, highly recommended",
      },
      {
        id: 2,
        userName: "Rakesh SK",
        addedOn: "23 min ago",
        text: "I agree, Peter",
      },
    ]);

    const setIsCommentsBlock = (value) => {
      isCommentsBlock.value = value;
    };

    const openModalBoost = () => {
      store.dispatch("content/setIsModalBoost", true);
    };

    const closenModalBoost = () => {
      store.dispatch("content/setIsModalBoost", false);
    };

    return {
      s,
      ...item,
      isCommentsBlock,
      comments,
      setIsCommentsBlock,
      openModalBoost,
    };
  },
};
</script>
