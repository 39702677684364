<template>
  <div :class="s.wrapper">
    <div :class="s.item" v-for="m in items" :key="m.id">
      <div :class="s.header">
        <div>
          <div :class="s.title">{{ m.title }}</div>
          <div :class="s.desc">{{ m.desc }}</div>
        </div>
        <div :class="s.linkWrapper">
          <route-link to="/" :class="s.link">Show All</route-link>
        </div>
      </div>
      <div :class="s.itemsWrapper">
        <Item v-for="i in m.items" :key="i.id" :title="i.title" :img="i.img" />
      </div>
    </div>
  </div>
</template>

<script>
import s from "./index.module.sass";

import Item from "./Item";

export default {
  components: { Item },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { s };
  },
};
</script>
