<template>
  <div ref="wrapperEl" :class="s.wrapper" :style="{ width: wrapperWidth }">
    <div v-for="i in items" :key="i.id" :class="s.svgWrapper">
      <div :class="s.header">
        <span>{{ i.type }}</span>
        <span>{{ i.value }}%</span>
      </div>
      <svg :class="s.svg" :width="width" height="20" data-value="40">
        <path
          :class="[s.bkg, s.path]"
          stroke="#fff"
          :d="`M0 10, ${width} 10`"
        ></path>
        <path
          :class="[s.path, s.line]"
          :stroke="i.color"
          :d="`M0 10, ${width} 10`"
          :style="{
            strokeDasharray: width,
            strokeDashoffset: Math.max(0, width * ((100 - i.value) / 100)),
          }"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    wrapperWidth: String,
    classes: String,
  },
  setup() {
    const wrapperEl = ref(null);
    const width = ref(400);

    // watch(() => {
    //   const coords = wrapperEl.value.getBoundingClientRect();
    //   width.value = coords.width;
    // });

    onMounted(() => {
      const coords = wrapperEl.value.getBoundingClientRect();
      width.value = coords.width;
    });

    return { s, wrapperEl, width };
  },
};
</script>
