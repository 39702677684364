<template>
  <div :class="s.wrapper">
    <div :class="s.title">Notifications</div>
    <Search :classes="s.search" placeholder="Search Notifications" />
    <div :class="s.notifications">
      <Notification
        v-for="(n, index) in notifications"
        :key="n.id"
        :username="n.username"
        :time_ago="n.time_ago"
        :text="n.text"
        :isLatest="index + 1 === notifications.length"
      />
    </div>
    <Button
      :isRounded="true"
      :disabled="true"
      width="100%"
      :classes="s.button"
      :height="50"
    >
      Load More
    </Button>
  </div>
  <div :class="s.aside"></div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Search from "@/components/Forms/Search";
import Notification from "@/components/NotificationItem";
import Button from "@/components/Common/Button";

export default {
  components: { Search, Notification, Button },
  setup() {
    const notifications = ref([
      {
        id: 1,
        username: "Mark Jones",
        time_ago: "2h ago",
        text: "Mark Jones Shared an article with you",
      },
      {
        id: 2,
        username: "John William",
        time_ago: "2h ago",
        text: "Shared a cousrse",
      },
    ]);

    return { s, notifications };
  },
};
</script>
