<template>
  <div :class="[s.wrapper, classes]">
    <slot />
    <div :class="s.buttons">
      <div
        v-for="i in itemsCount"
        :key="i"
        @click="() => setChoisenItem(i)"
        :class="[s.button, { [s.buttonActive]: choisenItem + 1 === i }]"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    itemsCount: {
      type: Number,
      required: true,
    },
    classes: String,
  },
  setup(props, { emit }) {
    const choisenItem = ref(0);

    const setChoisenItem = (value) => {
      choisenItem.value = value - 1;
      emit("setChoisenItem", choisenItem.value);
    };

    return { s, choisenItem, setChoisenItem };
  },
};
</script>
