<template>
  <div :class="s.wrapper">
    <Close @click="close" :classes="s.close" />
    <Card :classes="[s.modal, classes]">
      <slot />
    </Card>
    <div :class="s.bkg" />
  </div>
</template>

<script>
import s from "./index.module.sass";

import Card from "@/components/Common/Card";
import Close from "@/components/Icons/Close";

export default {
  components: { Card, Close },
  props: {
    close: Function,
    classes: String,
  },
  setup() {
    return { s };
  },
};
</script>
