<template>
  <div :class="[s.wrapper, { [s.wrapperOpen]: isMobMenu }]">
    <div :class="s.menu">
      <div div :class="s.header">
        <div :class="s.headerContent">
          <Avatar :img="avatar" :classes="s.avatar" />
          <div>
            <div :class="s.fullname">{{ name }} {{ surname }}</div>
            <div :class="s.job">{{ job }}</div>
          </div>
        </div>
        <Close :onclick="onClose" :classes="s.close" />
      </div>
      <div div :class="s.items">
        <div
          :class="[s.item, { [s.itemDisabled]: i.disabled }]"
          v-for="(i, index) in items"
          :key="index"
        >
          <router-link
            :to="i.url !== 'home' ? '/' + i.url : '/'"
            :class="s.link"
          >
            <div :class="s.iconWrapper">
              <img :src="i.icon" :alt="i.name" />
            </div>
            <div :class="s.text">{{ i.text }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import s from "./index.module.sass";

import Close from "@/components/Icons/Close";
import Avatar from "@/components/Common/Avatar";

export default {
  components: { Close, Avatar },
  props: {
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    surname: {
      type: String,
      required: true,
    },
    job: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const isMobMenu = computed(() => store.getters["app/isMobMenu"]);

    const onClose = () => {
      store.dispatch("app/setIsMobMenu", false);
    };

    return { s, isMobMenu, onClose };
  },
};
</script>
