<template>
  <!-- <Slider
    :classes="[s.wrapper, classes]"
    :itemsCount="items.length"
    @setChoisenItem="setChoisenItem"
  > -->
  <div :class="s.wrapper">
    <a :href="url" target="_blank" :class="s.link">
      <div :class="s.header">
        <!-- <div :class="s.fullname">{{ i.name }} {{ i.surname }}</div> -->
        <!-- <div :class="s.action">{{ i.action }}</div> -->
      </div>
      <div :class="s.content">
        <div
          :class="s.mainImgWrapper"
          :style="{
            background: `url(${thumbnail}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        >
          <div :class="s.mainImgRow">
            <!-- <div :class="s.statistic">{{ i.statistic }}%</div> -->

            <div :class="s.views">{{ views }} Views</div>
          </div>
          <div :class="s.mainImgContent">
            <div :class="s.title">{{ title }}</div>
            <div :class="s.body">{{ description }}</div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <!-- </Slider> -->
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Slider from "@/components/Common/Slider";

export default {
  components: { Slider },
  props: {
    item: {
      type: Object,
      required: true,
    },
    classes: String,
  },
  setup({ item }) {
    const choisenItem = ref(0);

    const setChoisenItem = (value) => {
      choisenItem.value = value;
    };

    return { s, ...item, choisenItem, setChoisenItem };
  },
};
</script>
