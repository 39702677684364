<template>
  <div :class="[s.wrapper, classes]">
    <div :class="s.icon"></div>
    <div :class="s.countWrapper">
      <span :class="s.count">{{ sharedCount }}</span>
      shared
    </div>
    <div :class="s.text">
      {{ title }} <span :class="s.skill">{{ skill }}</span>
    </div>
  </div>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    skill: {
      type: String,
      required: true,
    },
    sharedCount: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    classes: String,
  },
  setup() {
    return { s };
  },
};
</script>
