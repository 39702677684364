<template>
  <svg
    width="20"
    height="8"
    viewBox="0 0 20 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-1.74846e-07 4L6 0.535898L6 7.4641L-1.74846e-07 4Z"
      fill="#262626"
    />
    <path d="M20 4L14 7.4641L14 0.535898L20 4Z" fill="#262626" />
  </svg>
</template>
