<template>
  <div :class="s.wrapper" :style="{ color }">
    <dvi :class="s.value">{{ value }}%</dvi>
    <dvi :class="s.skill">{{ name || "skil" }}</dvi>
  </div>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    color: String,
  },
  setup() {
    return { s };
  },
};
</script>
