<template>
  <div :class="s.wrapper">
    <Navbar :isDetails="route.name === 'Article'" />
    <div :class="s.grid">
      <MenuDesktop :items="menuItems" />
      <MenuMobLeft :items="menuItems" name="Name" surname="Surname" job="Job" />
      <MenuMobBottom :items="menuBottomItems" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import HomeLogo from "@/assets/images/icons/home.svg";
import CompassLogo from "@/assets/images/icons/compass.svg";
import ActivitiesLogo from "@/assets/images/icons/activities.svg";
import BellLogo from "@/assets/images/icons/bell.svg";
import UserLogo from "@/assets/images/icons/user.svg";
import ExitLogo from "@/assets/images/icons/exit.svg";

import s from "./index.module.sass";

import MenuDesktop from "@/components/Common/Menu/Desktop";
import MenuMobBottom from "@/components/Common/Menu/Mobile/Bottom";
import MenuMobLeft from "@/components/Common/Menu/Mobile/Left";
import Navbar from "@/components/Common/Navbar";

export default {
  name: "main-layout",
  components: { MenuDesktop, MenuMobBottom, MenuMobLeft, Navbar },
  setup() {
    const route = useRoute();
    const user = ref({
      avatar: "",
      name: "Mark",
      surname: "Bolt",
      job: "Application developer",
    });

    const menuItems = [
      { url: "home", text: "Home", icon: HomeLogo },
      { url: "discover", text: "Discover", icon: CompassLogo },
      { url: "activities", text: "Activities", icon: ActivitiesLogo },
      { url: "notifications", text: "Notifications", icon: BellLogo },
      { url: "profile", text: "Profile", icon: UserLogo },
      { url: "logout", text: "Logout", icon: ExitLogo },
    ];
    const menuBottomItems = [
      { url: "home", text: "Home", icon: HomeLogo },
      { url: "notifications", text: "Notifications", icon: BellLogo },
      { url: "discover", text: "Discover", icon: CompassLogo },
    ];

    return { s, route, user, menuItems, menuBottomItems };
  },
};
</script>
