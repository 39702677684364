<template>
  <div :class="[s.wrapper, classes]">
    <div
      v-for="(i, index) in items"
      :key="index"
      :class="[s.item, { [s.itemActive]: index === activeItem }]"
      @click="() => setActiveItem(index)"
    >
      {{ i }}
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    classes: String,
  },
  setup(props, { emit }) {
    const activeItem = ref(0);

    const setActiveItem = (value) => {
      activeItem.value = value;
      emit("activeItem", activeItem.value);
    };

    return { s, activeItem, setActiveItem };
  },
};
</script>
