import { eventApi } from "@/api";

export default {
  async setItems({ commit }, { pageSize, pageNumber }) {
    console.log("setItems");
    const res = await eventApi.getItems(pageSize, pageNumber);
    console.log("setItems.res", res);

    if (JSON.stringify(res.messages) === "[]") {
      commit("setItems", res.events);
      commit("setPageNumber", res.pageNumber);
      commit("setPageSize", res.pageSize);
      commit("setTotalResults", res.totalResults);
    }
  },
};
