<template>
  <router-link to="/" :class="[s.wrapper, classes]">
    <Logo :classes="s.icon" />
    <div :class="s.text">Career Engine</div>
  </router-link>
</template>

<script>
import s from "./index.module.sass";

import Logo from "@/components/Icons/Logo";

export default {
  components: { Logo },
  props: { classes: String },
  setup() {
    return { s };
  },
};
</script>
