<template>
  <div :class="s.wrapper">
    <div :class="s.skills">
      <div :class="s.rateNames">
        <div
          v-for="(r, index) in rateNames"
          :key="index"
          :class="s.rateNamesItem"
        >
          {{ r }}
        </div>
      </div>
      <div :class="s.skill" v-for="(skill, index) in skills" :key="index">
        <div :class="s.skillName">{{ skill.name }}</div>
        <Steps :item="skill" :steps="4" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Steps from "@/components/Forms/Steps";

export default {
  components: { Steps },
  setup() {
    const skills = ref([
      { name: "Japan", level: "" },
      { name: "Eng", level: "" },
      { name: "Python", level: "" },
    ]);
    const rateNames = ref(["Beginner", "Intermediate", "Advancaed", "Master"]);

    return { s, skills, rateNames };
  },
};
</script>
