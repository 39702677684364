<template>
  <ProfileLayout @setActiveItem="setActiveItem">
    <Dashboard v-if="activeItem === 0" />
    <Account v-if="activeItem === 1" />
  </ProfileLayout>
</template>

<script>
import { ref } from "vue";
import { Auth } from "aws-amplify";

import s from "./index.module.sass";

import ProfileLayout from "@/layouts/Profile";

import Dashboard from "@/modules/Profile/Dashboard";
import Account from "@/modules/Profile/Account";

export default {
  components: { ProfileLayout, Dashboard, Account },

  setup() {
    const activeItem = ref(0);

    const user = ref(null);
    const setActiveItem = (value) => {
      activeItem.value = value;
    };

    return { s, user, activeItem, setActiveItem };
  },
};
</script>
