<template>
  <div :class="[s.wrapper, classes]">
    <div :class="s.header">
      <span :class="s.title">System Design</span>
      <span :class="s.value">58%</span>
    </div>
    <Line :items="items2" />
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Line from "@/components/Common/Chart/Line";

export default {
  components: { Line },
  props: {
    classes: String,
  },
  setup() {
    const items2 = ref([
      { id: 1, type: "Self", value: 20, color: "pink" },
      { id: 2, type: "Peer", value: 40, color: "lightgreen" },
    ]);

    return { s, items2 };
  },
};
</script>
