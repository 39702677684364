import { userFeedApi } from "@/api";

export default {
  async setItems({ commit }, { pageSize, pageNumber, search }) {
    const res = await userFeedApi.getItems(pageSize, pageNumber, search);
    console.log(res);
    commit("setItems", res.items);
    commit("setPageNumber", res.pageNumber);
    commit("setPageSize", res.pageSize);
    commit("setTotalResults", res.totalResults);
  },
};
