<template>
  <button
    @click="click"
    :class="[
      s.button,
      classes,
      { [s.disabled]: disabled, [s.rounded]: isRounded },
    ]"
    :style="{
      width,
      height: `${height}px`,
      backgroundColor,
      borderRadius: `${borderRadius}px`,
      fontSize: `${fontSize}px`,
    }"
    :htmlType="htmlType"
  >
    <slot></slot>
  </button>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: {
    htmlType: String,
    width: String,
    height: Number,
    backgroundColor: String,
    borderRadius: Number,
    click: Function,
    fontSize: Number,
    classes: String,
    disabled: Boolean,
    isRounded: Boolean,
  },
  setup() {
    return { s };
  },
};
</script>
