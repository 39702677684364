<template>
  <Slider
    :class="s.wrapper"
    :itemsCount="items.length"
    @setChoisenItem="setChoisenItem"
  >
    <div v-for="(i, index) in items" :key="i.id" :class="s.itemWrapper">
      <div v-if="choisenItem === index">
        <div :class="s.content">
          <div :class="s.avatarWrapper">
            <ExpertIcon :class="s.avatarIcon" />
            <Avatar classes="s.avatar" />
          </div>
          <div :class="s.contentRows">
            <div :class="s.contentRowsFirst">
              <span :class="s.name">{{ i.name }}</span>
              <div :class="s.years">
                <span :class="s.yearsCount">{{ i.years }}+</span>Yrs
              </div>
            </div>
            <div :class="s.contentRowsCecond">Principal achievement</div>
          </div>
        </div>
        <div :class="s.datetime">
          <ClockIcon :class="s.datetimeIcon" />
          <span :class="s.day">{{ i.day }}</span>
          <span :class="s.time">{{ i.time }}</span>
        </div>
        <div :class="s.btnWrapper">
          <Button width="100%" :height="45">BOOK AN APPOINTMENT</Button>
        </div>
      </div>
    </div>
  </Slider>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Slider from "@/components/Common/Slider";
import Avatar from "@/components/Common/Avatar";
import Button from "@/components/Common/Button";
import ExpertIcon from "@/components/Icons/Expert";
import ClockIcon from "@/components/Icons/Clock";

export default {
  components: { Slider, Avatar, Button, ExpertIcon, ClockIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    years: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  setup() {
    const items = ref([
      {
        id: 1,
        name: "Olivia",
        years: 15,
        day: "Tue, Fr.",
        time: "3.30 - 4.30 PM",
      },
      {
        id: 2,
        name: "Olivia",
        years: 17,
        day: "Tue, Fr.",
        time: "3.30 - 4.30 PM",
      },
      {
        id: 3,
        name: "Maks",
        years: 15,
        day: "Tue, Fr.",
        time: "3.30 - 4.30 PM",
      },
    ]);
    const choisenItem = ref(0);

    const setChoisenItem = (value) => {
      choisenItem.value = value;
    };

    return { s, items, choisenItem, setChoisenItem };
  },
};
</script>
