<template>
  <div :class="[s.wrapper, classes]">
    <div :class="s.header">
      <Avatar :classes="s.avatar" />
      <div>
        <div :class="s.row">
          <!-- <span :class="s.fullname">{{ name }} {{ surname }}</span> -->
          <span :class="s.timeAgo">{{ addedOn }}</span>
        </div>
        <div :class="s.action">{{ action }}</div>
      </div>
    </div>
    <div :class="s.contentWrapper">
      <div :class="s.imgWrapper"></div>
      <div :class="s.content">
        <div :class="s.title">{{ title }}</div>
        <div :class="s.desc">{{ description }}</div>
      </div>
    </div>
    <Audio :classes="s.audio" />
    <div :class="s.actions">
      <Likes
        :classes="s.likesCount"
        :contentId="id"
        :count="likes"
        :isLiked="isLiked"
      />
      <CommentsCount
        :classes="commentsCount"
        :count="comments"
        @isCommentsBlock="setIsCommentsBlock"
      />
    </div>
    <div v-if="isCommentsBlock" :class="s.commentsWrapper">
      <Comments :contentId="id" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import s from "./index.module.sass";

import Avatar from "@/components/Common/Avatar";
import Audio from "@/components/Common/Audio";
import Likes from "@/components/Common/Button/LikesCount";
import CommentsCount from "@/components/Common/Button/CommentsCount";
import Comments from "@/components/Comments";

export default {
  components: { Avatar, Audio, Likes, CommentsCount, Comments },
  props: {
    item: {
      type: Object,
      required: true,
    },
    classes: String,
  },
  setup({ item }) {
    const isCommentsBlock = ref(false);

    const setIsCommentsBlock = (value) => {
      isCommentsBlock.value = value;
    };

    return { s, ...item, isCommentsBlock, comments, setIsCommentsBlock };
  },
};
</script>
